var render = function () {
  var _vm$invoicesNotToCanc, _vm$invoicesNotToCanc2, _vm$invoicesToCancel;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-cancel-batch-invoices",
      "size": "xl",
      "centered": "",
      "header-bg-variant": "light-primary",
      "no-close-on-backdrop": "",
      "body-class": "p-1",
      "footer-class": "justify-content-between",
      "title-class": "h2 font-weight-bolder text-dark",
      "title": _vm.$t('invoice.cancel.title'),
      "hide-footer": ""
    },
    on: {
      "show": _vm.onShowHandle
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-table', {
    ref: "refCancelBatchInvoiceListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.invoicesToCancel,
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "bordered": "",
      "fields": _vm.tableColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key,
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.cancel.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(data.index + 1))])];
      }
    }, {
      key: "cell(symbol)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.templateNumber) + _vm._s(item.symbol) + " ")];
      }
    }, {
      key: "cell(invNumber)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-dark fw-700"
        }, [_vm._v(" " + _vm._s(item.invNumber) + " ")])];
      }
    }, {
      key: "cell(buyerName)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.buyerName) + " ")])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {}, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.createdAt).date) + " ")])];
      }
    }, {
      key: "cell(releasedInvCode)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticStyle: {
            "max-width": "120px",
            "overflow-wrap": "break-word"
          }
        }, [_vm._v(" " + _vm._s(item.releasedInvCode) + " ")])];
      }
    }, {
      key: "cell(totalAmount)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalAmount)) + " ")])];
      }
    }, {
      key: "cell(tbssStatus)",
      fn: function fn() {
        return [_c('BBadge', {
          attrs: {
            "variant": "light-danger",
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('invoice.tbssStatus.notSent')) + " ")])];
      },
      proxy: true
    }, {
      key: "cell(receiverName)",
      fn: function fn(_ref7) {
        var item = _ref7.item,
          index = _ref7.index;
        return [_c('validation-provider', {
          attrs: {
            "rules": "",
            "name": _vm.$t('invoice.cancel.receiverName'),
            "vid": "receiverName-".concat(index)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('b-form-group', {
                staticClass: "mb-0 min-width-200",
                attrs: {
                  "label-for": "receiverName"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "overflow": "auto"
                },
                attrs: {
                  "id": "receiverName-".concat(index),
                  "placeholder": _vm.$t('invoice.cancel.receiverName'),
                  "state": errors[0] ? false : null
                },
                model: {
                  value: item.receiverName,
                  callback: function callback($$v) {
                    _vm.$set(item, "receiverName", typeof $$v === 'string' ? $$v.trim() : $$v);
                  },
                  expression: "item.receiverName"
                }
              })], 1)];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(email)",
      fn: function fn(_ref9) {
        var item = _ref9.item,
          index = _ref9.index;
        return [_c('validation-provider', {
          attrs: {
            "rules": "email",
            "name": _vm.$t('invoice.cancel.email'),
            "vid": "email-".concat(index)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('b-form-group', {
                staticClass: "mb-0 min-width-200",
                attrs: {
                  "label-for": "email"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "overflow": "auto"
                },
                attrs: {
                  "id": "email-".concat(index),
                  "placeholder": _vm.$t('invoice.cancel.email'),
                  "state": errors[0] ? false : null
                },
                model: {
                  value: item.email,
                  callback: function callback($$v) {
                    _vm.$set(item, "email", typeof $$v === 'string' ? $$v.trim() : $$v);
                  },
                  expression: "item.email"
                }
              })], 1)];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(cc)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('validation-provider', {
          attrs: {
            "rules": "",
            "name": _vm.$t('invoice.cancel.cc'),
            "vid": "cc"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var errors = _ref12.errors;
              return [_c('b-form-group', {
                staticClass: "mb-0 min-width-200",
                attrs: {
                  "label-for": "cc"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "overflow": "auto"
                },
                attrs: {
                  "id": "cc",
                  "placeholder": _vm.$t('invoice.cancel.ccBccJoinCommaPlaceholder'),
                  "state": errors[0] ? false : null
                },
                model: {
                  value: item.cc,
                  callback: function callback($$v) {
                    _vm.$set(item, "cc", typeof $$v === 'string' ? $$v.trim() : $$v);
                  },
                  expression: "item.cc"
                }
              })], 1)];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(bcc)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('validation-provider', {
          attrs: {
            "rules": "",
            "name": _vm.$t('invoice.cancel.bcc'),
            "vid": "bcc"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref14) {
              var errors = _ref14.errors;
              return [_c('b-form-group', {
                staticClass: "mb-0 min-width-200",
                attrs: {
                  "label-for": "bcc"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "overflow": "auto"
                },
                attrs: {
                  "id": "bcc",
                  "placeholder": _vm.$t('invoice.cancel.ccBccJoinCommaPlaceholder'),
                  "state": errors[0] ? false : null
                },
                model: {
                  value: item.bcc,
                  callback: function callback($$v) {
                    _vm.$set(item, "bcc", typeof $$v === 'string' ? $$v.trim() : $$v);
                  },
                  expression: "item.bcc"
                }
              })], 1)];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(reason)",
      fn: function fn(_ref15) {
        var item = _ref15.item,
          index = _ref15.index;
        return [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": _vm.$t('invoice.cancel.reason'),
            "vid": "reason-".concat(index)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref16) {
              var errors = _ref16.errors;
              return [_c('b-form-group', {
                staticClass: "mb-0 min-width-200",
                attrs: {
                  "label-for": "reason"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "reason-".concat(index),
                  "placeholder": _vm.$t('invoice.cancel.reason'),
                  "state": errors[0] ? false : null
                },
                model: {
                  value: item.reason,
                  callback: function callback($$v) {
                    _vm.$set(item, "reason", typeof $$v === 'string' ? $$v.trim() : $$v);
                  },
                  expression: "item.reason"
                }
              })], 1)];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(cancelDate)",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": _vm.$t('invoice.cancel.cancelDate'),
            "vid": "cancelDate"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref18) {
              var errors = _ref18.errors;
              return [_c('b-form-group', {
                staticClass: "mb-0",
                staticStyle: {
                  "width": "120px"
                },
                attrs: {
                  "label-for": "cancelDate"
                }
              }, [_c('flat-pickr', {
                staticClass: "d-none",
                model: {
                  value: item.cancelDate,
                  callback: function callback($$v) {
                    _vm.$set(item, "cancelDate", $$v);
                  },
                  expression: "item.cancelDate"
                }
              }), _c('div', {
                staticClass: "p-0 form-control",
                class: errors[0] ? 'border-danger is-invalid' : 'border-transparent'
              }, [_c('flat-pickr', {
                attrs: {
                  "id": "cancelDate",
                  "name": _vm.$t('invoice.cancel.cancelDate'),
                  "config": Object.assign({}, _vm.configFlatPickr, {
                    altInputClass: 'form-control border-transparent px-50 py-0 height-25rem'
                  }),
                  "placeholder": _vm.$t('topup.placeholderSelectDate')
                },
                model: {
                  value: item.cancelDate,
                  callback: function callback($$v) {
                    _vm.$set(item, "cancelDate", $$v);
                  },
                  expression: "item.cancelDate"
                }
              })], 1)], 1)];
            }
          }], null, true)
        })];
      }
    }], null, true)
  }), (_vm$invoicesNotToCanc = _vm.invoicesNotToCancel) !== null && _vm$invoicesNotToCanc !== void 0 && _vm$invoicesNotToCanc.length ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('invoice.cancel.invoiceCannotCancel')) + " "), _c('b', {
    staticClass: "text-info fw-700"
  }, [_vm._v("(" + _vm._s((_vm$invoicesNotToCanc2 = _vm.invoicesNotToCancel) === null || _vm$invoicesNotToCanc2 === void 0 ? void 0 : _vm$invoicesNotToCanc2.length) + ")")]), _vm._v(" - "), _vm._l(_vm.invoicesNotToCancel, function (invoiceNotToCancelItem, invoiceNotToCancelIndex) {
    return _c('span', {
      key: invoiceNotToCancelIndex
    }, [_c('span', {
      staticClass: "fw-700 font-italic text-danger"
    }, [_vm._v(_vm._s(invoiceNotToCancelItem))]), invoiceNotToCancelIndex !== _vm.invoicesNotToCancel.length - 1 ? _c('span', [_vm._v(", ")]) : _vm._e()]);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "d-flex-center justify-content-end gap-2 mt-1"
  }, [_c('BButton', {
    attrs: {
      "variant": "flat-dark"
    },
    on: {
      "click": _vm.onCloseHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " ")]), _c('BButton', {
    attrs: {
      "variant": "danger",
      "disabled": !((_vm$invoicesToCancel = _vm.invoicesToCancel) !== null && _vm$invoicesToCancel !== void 0 && _vm$invoicesToCancel.length)
    },
    on: {
      "click": _vm.onSubmitHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.cancel.title')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }